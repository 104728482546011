import { getProjectEnergy, updateProjectEnergy } from '@/services/projects'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    type: 'checkbox',
    name: 'epb_in_draft',
    label: 'EPB in opmaak',
    '@change': 'epbInDraftChange'
  },
  {
    component: 'div',
    id: 'epbDetailsGroup',
    class: 'tw-my-4 tw-my-4 tw-hidden',
    children: [
      {
        component: 'span',
        children: 'Onderstaande velden moeten enkel worden ingevuld wanneer het EPB beschikbaar is (niet in opmaak).'
      },
      {
        type: 'text',
        name: 'epb',
        label: 'EPB-kengetal',
        placeholder: 'EPB-kengetal'
      },
      {
        type: 'text',
        name: 'epb_label',
        label: 'EPB-label',
        placeholder: 'EPB-label'
      },
      {
        type: 'text',
        name: 'e_value',
        label: 'E-peil',
        placeholder: 'E-peil'
      },
      {
        type: 'text',
        name: 'uc',
        label: 'UC',
        placeholder: 'UC'
      },
      {
        type: 'date',
        name: 'epb_date',
        label: 'EPB-opstellingsdatum',
        placeholder: 'EPB-opstellingsdatum'
      }
    ]
  }
]

const actions = {
  async load (projectId) {
    try {
      const response = await getProjectEnergy(projectId)
      return response.data
    } catch (error) {
      errorModal('Fout bij het laden van energiegegevens, probeer het opnieuw.')
      throw error
    }
  },
  async submit (projectId, data) {
    try {
      // epb_in_draft is the only value that should be updated when the checkbox is checked
      const epb_in_draft = data?.epb_in_draft
      if (epb_in_draft) data = { epb_in_draft }
      const response = await updateProjectEnergy(projectId, data)
      successModal('De wijzigingen aan de energiegegevens zijn succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Er ging iets fout bij het wijzigen van de energiegegevens. Gelieve nogmaals te proberen.')
      throw error
    }
  }
}

export default {
  heading: 'Energiegegevens',
  schema,
  actions
}
